export default
  {
    es: [{ id: 'MALE', name: 'Hombre' },
    { id: 'FEMALE', name: 'Mujer' },
    { id: 'OTHER', name: 'Otro' },
    { id: 'RATHER-NOT-SAY', name: 'Prefiero no responder' }],

    en: [{ id: 'MALE', name: 'Male' },
    { id: 'FEMALE', name: 'Female' },
    { id: 'OTHER', name: 'Other' },
    { id: 'RATHER-NOT-SAY', name: 'Rather not say' }]
  }