<template>
  <div class="main-content auth">
    <div class="auth-container box">
      <Switch :switchValue="switchValue" @switchValueChanged="switchValue = !switchValue"/>
      <img class="auth__logo" src="@/assets/images/logos/LogoMeaning.svg">
      <div class="auth__titles">
        <h2>{{$t('singup.registrate')}}</h2>
        <p class="subtitle">{{$t('singup.ingresadatos')}}</p>
      </div> 
      <div class="auth__form">
        <!-- <div class="auth__social">
          <div class="auth__social-button auth__social-button--facebook">
            <img src="@/assets/images/logos/facebook.svg">
          </div>
          <div class="auth__social-button auth__social-button--google">
            <img src="@/assets/images/logos/google.svg">
          </div>
        </div> -->
        <div class="auth__own">
          <form @submit.prevent="signup()">
            <div class="grid grid-2">
              <form-input type="text" v-model.trim="first_name" :required="true" :autofocus="true">
                {{$t('singup.nombre')}}
              </form-input>
              <form-input type="text" v-model.trim="last_name" :required="true">
                {{$t('singup.apellido')}}
              </form-input>
            </div>
            <form-input type="tel" v-model.trim="phone">
              {{$t('singup.telefono')}}
            </form-input>
            <form-input type="text" v-model.trim="address">
              {{$t('singup.direccion')}}
            </form-input>
            <form-input  v-if="$i18n.locale == 'es'" v-model="sex" type="select" :options="sexes.es" selectPrompt="Seleccione" :required="true">
              {{$t('singup.sexo')}}
            </form-input>
             <form-input  v-else-if="$i18n.locale == 'en'" v-model="sex" type="select" :options="sexes.en" selectPrompt="Select" :required="true">
              {{$t('singup.sexo')}}
            </form-input>
            <form-input type="email" v-model.trim="email" :required="true">
              {{$t('singup.correo')}}
            </form-input>
            <form-input type="password" v-model="password" :required="true">
              {{$t('singup.contra')}}
            </form-input>
            <form-input type="password" v-model="passwordConfirm" :required="true">
              {{$t('singup.confirmcontra')}}
            </form-input>
            <form-input v-if="$i18n.locale == 'es'" type="radioGroup" :groupOptions="roleses" v-model="role_id">
              {{$t('singup.selecperfil')}}
            </form-input>
            <form-input v-if="$i18n.locale == 'en'" type="radioGroup" :groupOptions="rolesen" v-model="role_id">
              {{$t('singup.selecperfil')}}
            </form-input>
            <template v-if="role_id === 2">
              <div v-if="$i18n.locale == 'es'">
                <div :style="text">
                  {{$t('singup.fechanacimiento')}}
                </div>
                <datepicker :style="calendar" v-model="picked" inputFormat="dd-MM-yyyy"  :upper-limit="upperlimit" :locale="dateEs"/>
              </div>

              
              <div v-else-if="$i18n.locale == 'en'">
                <div :style="text">
                  {{$t('singup.fechanacimiento')}}
                  
                </div>
                <datepicker :style="calendar" v-model="picked" inputFormat="dd/MM/yyyy"  :upper-limit="upperlimit" :locale="dateEn"/>
              </div>

                

              <form-input v-if="$i18n.locale == 'es'" v-model="grade_id" type="select" :options="grades_es.slice(0,8)" selectPrompt="Seleccione">
                <!-- quitar slice ya que es algo temporal -->
                {{$t('singup.curso')}}
              </form-input>
              
                
              <form-input v-else-if="$i18n.locale == 'en'" v-model="grade_id" type="select" :options="grades_en.slice(0,8)" selectPrompt="Select">
                <!-- quitar slice ya que es algo temporal -->
                {{$t('singup.curso')}}
              </form-input>
              
              <!--<form-input type="custom"> descomentar para despues de presentacion es usa
                <template #default>{{$t('singup.colegio1')}}</template>
                <template #custom-input>
                  <debounced-select @searchTerm="fetchSchools($event, firstSchoolOptions); school_id_1 = null" @selectedOption="school_id_1 = captureSelectedOption($event, firstSchoolOptions)" :options="firstSchoolOptions" :selectedValue="school_id_1"/>
                </template>
              </form-input>
              <form-input type="custom">
                <template #default>{{$t('singup.colegio2')}}</template>
                <template #custom-input>
                  <debounced-select @searchTerm="fetchSchools($event, secondSchoolOptions); school_id_2 = null" @selectedOption="school_id_2 = captureSelectedOption($event, secondSchoolOptions)" :options="secondSchoolOptions" :selectedValue="school_id_2"/>
                </template>
              </form-input>-->
            </template>
            <div class="form__actions">
              <button class="btn btn--primary btn--block" type="submit" :disabled="!password || password !== passwordConfirm || !role_id || (role_id === 2 && !school_id_1)">{{$t('singup.crearcuenta')}}</button>
              <!-- cambiar school_id_1 y school_id_2 a null en data -->
              <router-link :to="{ name: 'Login' }" class="btn btn--primary btn--link btn--block">{{$t('singup.iniciaSesion')}}</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<!--import DebouncedSelect from '@/components/DebouncedSelect' poner en imports-->
<!--DebouncedSelect poner en components-->

<script>
import { inject, reactive} from 'vue'
import FormInput from '@/components/FormInput'

import sexes from '@/utils/sexes'
import Switch from '../components/Switch.vue'
import Datepicker from 'vue3-datepicker'
import { es,en } from 'date-fns/locale'

export default {
  name: 'Signup',
  setup () {
    const axios = inject('$axios')
    const roleses= reactive([
      { id: 2, name: 'Profesor' },
      { id: 3, name: 'Investigador' }
    ])
    const rolesen= reactive([
      { id: 2, name: 'Teacher' },
      { id: 3, name: 'Researcher' }
    ])

    
    /* antigua llamada a grades
    const grades = reactive([])

    axios.get('grades').then(res => res.data.grades.forEach(grade => grades.push(grade)))
    console.log(grades)*/


    const grades_en = reactive([])
    const grades_es = reactive([])
    
    axios.get('grades-en').then(res => res.data.grades_en.forEach(grade => grades_en.push(grade)))


    axios.get('grades-es').then(res => res.data.grades_es.forEach(grade => grades_es.push(grade)))


    return { axios, roleses, rolesen, grades_en, grades_es}
    
  },

components: { FormInput,Switch,Datepicker},



  data () {
    return {
      sexes,
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      passwordConfirm: null,
      role_id: null,
      phone: null,
      address: null,
      sex: null,
      school_id_1: 11265,
      school_id_2: 11265,
      grade_id: null,
      firstSchoolOptions: [],
      secondSchoolOptions: [],
      switchValue: true,
      picked: new Date(),
      upperlimit:new Date(),
      dateEn: en,
      dateEs: es,
      usedate:null,
        calendar:{
          height: '50px',
          width: '100%',
          maxWidth: '500px',
          padding: 'var(--space-no) var(--space-n)',
          margin: 'var(--space-no)',
          backgroundColor: 'var(--color-white)',
          border: '1px solid var(--color-tertiary--95)',
          borderRadius: 'var(--radius)',
          lineHeight: '28px',
          MsBoxSizing:' border-box',
          OBoxSizing: 'border-box',
          boxSizing: 'border-box',
          "--vdp-selected-bg-color": "var(--color-primary)",
          "--vdp-hover-bg-color": "var(--color-primary)",
          "--vdp-box-shadow": "0 4px 10px 0 rgba(0, 0, 0, 0.8), 0 0 1px 0 rgba(128, 144, 160, 0.81)",
          "--vdp-text-color": "#000000",
          "--vdp-elem-font-size": "1em",

        },
        text:{
          display:' block',
          fontFamily: "RobotoCondensed-Bold",
          fontSize:' var(--caption-xs)',
          textTransform: 'uppercase',
          lineHeight: 'var(--caption)',
          marginBottom: 'var(--space-xs)',
          color: 'var(--color-text)',
          marginLeft: 'var(--space-xs)',
          textAlign: 'left'
        }

    }
  },
  methods: {
    async signup () {
      const res = await this.axios.post('auth/signup', this.formSubmitData)
      if (res.data.token) {
        let authToken = `bearer ${res.data.token}`
        this.axios.defaults.headers.common['Authorization'] = authToken
        this.axios.get('init').then(() => {
          this.$cookies.set('jwt', authToken)
          this.$router.push('/')
        })
      } else {
        console.log(res)
        alert(res.data.message)
      }
    },
    fetchSchools (searchTerm, resultArray) {
      if (!searchTerm) {
        resultArray.splice(0)
      } else {
        this.axios.get('schools', { params: { searchTerm } }).then(res => {
          resultArray.splice(0)
          resultArray.push(...res.data.schools)
        })
      }
    },
    captureSelectedOption(id, options) {
      if (id) {
        let selectedOption = options.find(option => option.id === id)
        options.splice(0)
        options.push(selectedOption)
      }
      return id
    },

  },
  computed: {
    formSubmitData () {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        role_id: this.role_id,
        phone: this.phone,
        address: this.address,
        sex: this.sex,
        picked: this.picked ? this.picked.toISOString().split('T')[0] : null,

      }

      if (this.role_id === 2) {
        data.school_id_1 = this.school_id_1
        data.school_id_2 = this.school_id_2
        data.grade_id = this.grade_id
        data.picked = this.picked ? this.picked.toISOString().split('T')[0] : null;

      }
      return data
    },
  },
  watch: {
    role_id () {
      if (this.role_id === 3) {
        this.school_id_1 = this.school_id_2 = null
        this.picked = null
        this.grade_id = null
        
      }
    },
  },
}
</script>